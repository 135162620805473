<script setup>

const {onMouseUp, onMouseMove} = useMouseEvents();

const props = defineProps({
  min: [String, Number],
  max: [String, Number],
  step: {
    type: Number,
    default: 1
  },
  units: String
})

const emits = defineEmits(['change']);

const maxValue = computed(() => props.max);
const minValue = computed(() => props.min);
const stepValue = computed(() => props.step);

const value = defineModel('value', {default: 0})
const lastValue = ref(value.value);
const handlePosition = ref('0px');

const positionBoundaries = computed(() => {
  return {
    min: 0,
    max: trackWidth.value - handleSize
  }
})

const handleSize = 40;
let currentHandleElement = null;

const dragging = ref(false);
const trackX = ref(0);
const trackWidth = ref(0);

const ratio = ref(1);

let handleDragOffsetX = 0;

const drag = (event) => {
  event.preventDefault();
  if (!dragging.value) return;
  const pageX = event.pageX || event.touches[0].pageX;
  let left = pageX - trackX.value - handleDragOffsetX;

  const minPosValue = positionBoundaries.value.min;
  const maxPosValue = positionBoundaries.value.max;

  left = Math.min(Math.max(left, minPosValue), maxPosValue);

  setHandlePosition(left)
  calculateValue(left)
}

const setHandlePosition = (position) => {
  handlePosition.value = position + "px";
}

const calculateValue = (position) => {
  const calculatedValue = Math.round(Math.round((position / ratio.value) / stepValue.value) * stepValue.value);
  value.value = calculatedValue + parseInt(minValue.value);
}

const startDrag = (event) => {
  dragging.value = true;
  currentHandleElement = event.target;
  const {x} = currentHandleElement.getBoundingClientRect();
  handleDragOffsetX = (event.pageX || event.touches[0].pageX) - x;
}

const calculateRatio = () => {
  if (trackWidth.value === 0) return 0;
  ratio.value = (trackWidth.value - handleSize) / (maxValue.value - minValue.value);
}

const updateTrack = () => {
  calculateRatio();
  setHandlePosition((value.value - minValue.value) * ratio.value);
}
const setTrack = (width, x) => {
  trackWidth.value = width;
  trackX.value = x;
  updateTrack();
}

const updateValueFromInput = ($event) => {
  const newValue = $event.target.value;
  value.value = Math.max(Math.min(newValue, maxValue.value), minValue.value);
  updateTrack()
}

onMouseMove(drag)
onMouseUp(() => {
  dragging.value = false;
  if(value.value !== lastValue.value) {
    emits('change', value.value);
    lastValue.value = value.value;
  }
})

onMounted(() => {
  updateTrack();
})

watch(() => [value.value, maxValue.value, minValue.value],
    (newValue) => {
      if (newValue[0] === null) {
        value.value = minValue.value;
      }
      if (value.value > maxValue.value) {
        value.value = maxValue.value;
      }
      if (value.value < minValue.value) {
        value.value = minValue.value;
      }
      updateTrack();
    },
    {deep: true}
);

</script>

<template>
  <div class="flex justify-center items-center gap-4">
    <div class="relative h-10 mt-1 grow">
      <InputRangeTrack ref="refTrack" @resize="(tw,x) => setTrack(tw,x)"
                       v-model:left-value="props.min"
                       v-model:right-value="handlePosition"
      />
      <InputRangeHandle @mousedown.prevent="startDrag" @touchstart.prevent="startDrag"
                        :style="{left: handlePosition}"
      />
    </div>
    <div>
      <div class="flex w-[54px] h-10 rounded border border-gray-400 text-gray-500 outline-none overflow-hidden">
        <input type="number" class="grow h-10 px-2 min-w-0 text-right outline-none"
               :class="{'pr-0': units}"
               v-model="value"
               @blur="updateValueFromInput"
               @keyup.enter="updateValueFromInput"
        />
        <div v-if="units" class="h-10 pr-2 flex items-center">
          {{ units }}
        </div>
      </div>
    </div>
  </div>
</template>
