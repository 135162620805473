<script setup>

const emits = defineEmits(['change']);

const {getCurrentParams, maxRepurchase, leasingParamsDefinition, setCurrentParams, onChange} = useLeasingParams()

const financingData = ref({...getCurrentParams()});
const maxRep = computed(() => maxRepurchase[financingData.value.duration])

const duration = computed(() => {
  const obj = {}
  leasingParamsDefinition.duration.forEach(i => obj[i.toString()] = i);
  return obj;
})

onChange(() => {
  emits('change')
});

const onValueChange = (type, value) => {
  useAnalytics().analyticsLayer({
    event: type,
    value: value
  })
}

let timer;
watch(
  () => financingData.value,
  (nv) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => setCurrentParams({...financingData.value}), 100);
  },
  {deep: true}
)

</script>

<template>
  <div>
    <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-4">
      <div>
        <div class="text-sm font-extralight mb-1">klient</div>
        <InputState :states="leasingParamsDefinition.client"
                    v-model:value="financingData.client"
                    @change="(v) => onValueChange('calculator_client',v)"
        />
      </div>
      <div>
        <div class="text-sm font-extralight mb-1">okres umowy</div>
        <InputState :states="duration"
                    v-model:value="financingData.duration"
                    @change="(v) => onValueChange('calculator_duration',v)"
        />
      </div>
      <div>
        <div class="text-sm font-extralight">opłata wstępna</div>
        <InputSlider v-model:value="financingData.initialFee"
                     :min="1" :max="50" units="%"
                     @change="(v) => onValueChange('calculator_oplata_wstepna',v)"
        />
      </div>
      <div>
        <div class="text-sm font-extralight">wartość wykupu</div>
        <InputSlider v-model:value="financingData.repurchase" :min="1" :max="maxRep" units="%"
                     @change="(v) => onValueChange('calculator_wartosc_wykupu',v)"
        />
      </div>
    </div>
  </div>
</template>
